// import { Link } from "gatsby"
import React from "react"
import icon1 from "../../images/icon/icon-01.png"
import icon2 from "../../images/icon/icon-02.png"
import icon3 from "../../images/icon/icon-03.png"
import icon4 from "../../images/icon/icon-04.png"
import "./style.scss"

const links = [
  { icon: icon1, link: "https://www.facebook.com/SchoolLiveMagazine", alt: "" },
  { icon: icon2, link: "https://twitter.com/schoolLIVE_mag", alt: "" },
  { icon: icon3, link: "https://www.instagram.com/schoolivemagazine", alt: "" },
  { icon: icon4, link: "https://www.linkedin.com/school/schoollive/", alt: "" },
]

export default function SocialMediaButtons() {
  const linksJSX = links.map((link, i) => (
    <div className="smbuttons__img">
      <a href={link.link} target="__blank">
        <img src={link.icon} alt={link.alt} />
      </a>
    </div>
  ))
  return <div className="smbuttons">{linksJSX}</div>
}
